#disbale-hover:hover {
    border-color: "#B81D2C" !important;
    box-shadow: none !important;
}

#countDown {
    top: 0;
    width: 100%;
    height: calc(100vh - 280px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.425);
}

#videoCountDown {
    height: calc(100vh - 280px);
    padding-bottom: 0;
}

#videoView {
    height: calc(100vh - 280px);
    padding-bottom: 0;
}

#videoParentView {
    max-height: calc(100vh - 280px);
    position: relative;
}

#selected-review {
    position: relative;
}

#response-wrapper form {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
}

#response-wrapper form span {
    padding: 10px 23px;
    display: block;
    background-color: #bdbf09;
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    max-width: 80px;
    width: 100%;
    border-radius: 0px 6px 0px 0px;
}

#response-wrapper .response-field {
    display: flex;
}

#response-wrapper textarea {
    width: 978px;
    resize: none;
    height: 65px;
    padding: 21px 25px;
    font-size: 14px;
    line-height: 1.5em;
    color: rgba(0, 0, 0, .75);
    border: 1px solid transparent;
    box-shadow: 0 -10px 30px rgb(0 0 0 / 10%);
    outline: none;
}

#response-wrapper input[type=submit] {
    background-color: #bdbf09;
    border: 1px solid transparent;
    outline: none;
    color: #ffffff;
    width: 20%;
    cursor: pointer;
}

#response-wrapper input[type=submit]:disabled {
    background-color: #bfc070;
    cursor: not-allowed;
}

#response-wrapper textarea:disabled {
    background-color: rgb(213 213 213);
    cursor: not-allowed;
    box-shadow: none
}

#pre-wrap {
    white-space: pre-wrap;
}

#response-submit-loader {
    background-color: #bdbf09;
    width: 22.5%;
    display: flex;
    justify-content: center;
    align-items: center
}

#review-loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #afacac;
    box-shadow: 0 10px 10px -6px rgb(0 0 0 / 10%)
}

#disabled {
    background-color: rgb(213 213 213);
    cursor: not-allowed;
}

.social-card {
    padding: 0px 20px 105px !important;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked+.switch::before {
    transform: translateX(25px);
    background-color: white;
}

.toggle-switch input[type="checkbox"]:checked+.switch {
    background-color: #1b79cb;
}

.disable-review {
    background-color: rgb(213 213 213);
    cursor: not-allowed;
}

.app-card-wrapper .app-card .flip .faces .face.front .description{
    justify-content: flex-start;
}

.app-card-wrapper .app-card.inactive .face .description{
    justify-content: flex-start;
}

.app-card-wrapper .app-card .description .description-title{
    margin: 0 0 28px;
    font-weight: 500;
    letter-spacing: 0.24px;
    color: #444444;
}

.app-card-wrapper .app-card.inactive .face .description .description-title{
    margin-bottom: 28px;
    font-weight: 500;
    letter-spacing: 0.24px;
    color: #666666;
}

.app-card-wrapper .app-card.inactive .face .description .description-sub-title{
    margin-top: 28px;
    color: #888888;
    letter-spacing: 0.5px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: normal;
}

.app-card-wrapper .app-card .description{
    padding: 24px 16px 16px;
}

.app-card-wrapper .app-card .flip .faces .face{
    border-radius: 16px;
    box-shadow: 0 3px 3px 0 rgba(26, 26, 26, 0.2), 0 2px 2px 0 rgba(26, 26, 26, 0.12), 0 0 2px 0 rgba(26, 26, 26, 0.14);
}

.app-card-wrapper .app-card .title{
    border-radius: 0 0 16px 16px;
    padding: 20px 32px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.app-card-wrapper .app-card.inactive .face {
    border-radius: 16px;
    background: #cccccc;
}

.app-card-wrapper .app-card.kenect .title,
.app-card-wrapper .app-card.kenect .cta-btn,
.app-card-wrapper .app-card.kenect .flip .faces .face.back {
  background: #1477f8;
}

.app-card-wrapper .app-card.kenect .description-title,
.app-card-wrapper .app-card.kenect .main-icon {
  color: #1477f8;
}

.app-card-wrapper .app-card .flip:hover .faces .face{
    box-shadow: 0 7px 8px 0 rgba(26, 26, 26, 0.2), 0 5px 22px 0 rgba(26, 26, 26, 0.12), 0 12px 17px 0 rgba(26, 26, 26, 0.14);
}

.calender .header {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calender {
    padding: 0 100px;
    max-width: 1500px;
    margin: 0 auto !important;
    min-height: 100vh;
}

.calender:not(:has(.rbc-month-view)) {
    height: calc(100vh - 250px);
    max-height: 725px;
}

.calender h3{
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.4px;
    color: #bcbcbc;
    margin-bottom: 35px;
}

.calender .rbc-event, .rbc-day-slot .rbc-background-event{
    background-color: transparent;
    padding: 4px 8px 4px 8px;
    justify-content: center;
}

.calender .rbc-event:focus-visible{
    outline: none;
}

.calender .rbc-month-row{
    overflow: inherit;
    min-height: 130px;
    max-height: 130px;
    height: 100%;
}

.calender .rbc-button-link{
    font-size: 12px;
    color: #000;
    line-height: 1.33;
    letter-spacing: 0.4px;
}

.calender .rbc-month-view .event-triggered{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.calender .rbc-time-view .event-triggered{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.calender .event-triggered:focus-visible{
    outline: none;
}

.calender .event-triggered span{
    color: #666;
    line-height: 1.6;
    letter-spacing: 0.4px;
    font-size: 12px;
    font-weight: 300;
    margin-left: 8px;
}

.calender .rbc-month-view .event-triggered i{
    color: #666;
    font-size: 16px;
    margin-left: 4px;
    margin-top: -2px;
}

.calender .rbc-month-view .event-triggered img{
    width: 26px;
    height: 26px;
    object-fit: cover;
    padding: 2px;
    border: 1px solid #000;
}

.calender .rbc-time-view .event-triggered i{
    color: #666;
    font-size: 14px;
    margin-left: 4px;
}

.calender .rbc-time-view .event-triggered img{
    object-fit: cover;
    padding: 2px;
    border: 1px solid #000;
}

.calender .rbc-row-segment .rbc-event-content{
    overflow: inherit;
}

.calender .rbc-row-segment .rbc-event-content:focus-visible{
    outline: none;
}

.calender .rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event{
    background-color: transparent;
}

.calender .rbc-event.rbc-selected:focus{
    outline: none;
}

.calender .rbc-toolbar .rbc-toolbar-label{
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.4px;
    color: #bcbcbc;
    text-transform: uppercase;
}

.modal-content-inner-wrapper.two-column.clearfix.social-post-modal {
    max-height: 6500px;
    overflow-y: auto;
}

.social-post-modal .ui.pointing.label {
    margin-top: 0.5em;
}

.social-post-modal label {
    display: block;
    margin-bottom: 8px !important;
}

.social-post-media {
    position: relative;
    background-color: #eee;
}

.social-post-media .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8 !important;
}

.social-post-media img {
    display: block;
    object-fit: contain;
    height: 300px;
    max-width: 100%;
    width: auto;
    margin: auto;
}

.social-post-media video {
    display: block;
    object-fit: contain;
    height: 300px;
    max-width: 100%;
    width: auto;
    margin: auto;
}

.social-post-media video:hover {
    cursor: pointer;
}

.social-post-media button {
    position: absolute;
    background: rgba(0, 0, 0, 0.5) !important;
    background-color: none !important;
    color: #fff !important;
}

.social-post-media i {
    color: #fff;
    opacity: 1 !important;
}

.ui.checkbox label:before {
    border-color: #e2e2e2 !important;
}

.checkbox-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 8px;
}

.checkbox-group .field {
    margin: 0 -0.5em 0 !important;
    text-transform: capitalize;
}

.media-library-select-container {
    overflow: auto;
    height: 300px;
}

.media-library-select {
    width: 100% !important;
    margin: 8px 0 !important;
}

.media-library-select .column {
    padding: 5px !important;
}

.media-library-select img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1;
}

.media-library-select .relative {
    position: relative;
}

.media-library-select .type-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}


.media-library-select video {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1;
}

.social-post-media-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    user-select: none;
}

.calender .rbc-row-segment:focus-visible{
    outline: none;
}

.kui-toggle-backdrop {
    border: 1px solid #bcbcbc !important;
}

.kui-toggle-wrapper {
    margin-left: 6px;
}

.kui-toggle-option {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    padding: 0 8px;
    border-radius: 2px;
    color: #bcbcbc !important;
    cursor: pointer;
}

.kui-toggle-option-active {
    background: #d86d27;
    color: white !important;
}

.kui-toggle-wrapper {
    display: flex;
}

.kui-toggle-backdrop {
    display: flex;
    padding: 2px;
    border-radius: 4px;
    user-select: none;
}

.kui-toggle-backdrop:hover {
    border-color: rgba(34,36,38,.35) !important;
}


.kui-toggle-option-wrapper label {
    display: block;
}

.kui-toggle-option-wrapper input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.kui-toggle-option-wrapper input[type="radio"]:focus-visible+label {
    box-shadow: 0px 0px 0px 2px var(--blue-5);
}

.kui-timepicker {
    width: 100%;
    padding: 12px;
    display: flex;
    border-radius: 4px;
    align-items: center;
}

.ui.bottom.left.popup:before {
    display: none;
}

.kui-react-date-picker {
    padding: 0;
}

.kui-react-date-picker .rdrMonthAndYearWrapper {
    padding-top: 0;
    padding-bottom: 10px;
    border-bottom: #eeeff0 1px solid;
    height: fit-content;
}

.kui-react-date-picker .rdrMonth {
    padding-top: 10px;
    padding-bottom: 10px;
}

.kui-react-date-picker .rdrWeekDay {
    font-size: 10px;
    font-weight: 100;
    color: #666;
    font-family: Oswald, Impact;
    text-transform: uppercase;
}

.kui-react-date-picker .rdrSelected {
    background-color: #d86d27;
    color: #fff !important;
}

.kui-react-date-picker .rdrDayEndPreview {
    border: none;
}

.kui-react-date-picker .rdrDayNumber {
    font-size: 12px;
    font-weight: normal;
}

.kui-date-picker-action-footer {
    border-top: #eeeff0 1px solid;
}

.kui-react-date-picker .rdrNextPrevButton {
    background-color: #fff
}

.kui-react-date-picker .rdrNextPrevButton:hover {
    background-color: #ddd
}

.kui-timepicker .kui-toggle-backdrop {
    border: 1px solid #ccc;
}

.kui-timepicker__select {
    width: 68px;
}

.kui-timepicker-seperator {
    margin: 0 3px;
    color: gray;
}

.kui-timepicker .kui-timepicker-tz {
    margin-left: 4px;
    margin-right: 20px;
}

.additional-info .item .status{
    display: flex;
}

.additional-info .item .status i{
    font-size: 18px;
    margin-right: 8px;
}

.calender .rbc-day-slot .rbc-event,
.calender  .rbc-day-slot .rbc-background-event{
    border: none;
    min-height: 30px;
}

.calender .rbc-event-label{
    display: none;
}

.calender #no-data{
    min-height: 710px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calender #no-data i{
    font-size: 2em;
}

.calender #no-data span{
    font-size: 30px;
}

.ui.custom.table tr td .rss-feed{
    display: flex;
    margin-top: 5px;
    margin-bottom: 0;
}

.ui.custom.table tr td .rss-feed h3{
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 0;
    font-size: 1.09em;
    text-transform: capitalize;
}

.ui.custom.table tr td .rss-feed i {
    margin-left: 10px;
    cursor: pointer;
}

.app-selection .app-card-container .message{
    width: 78%;
    margin: 0 auto 40px;
    text-align: center;
    font-size: 16px;
}

@media only screen and (max-width: 1408px){
    .app-selection .app-card-container .message{
        width: 100%;
        font-size: 14px;
    }
}

@media (max-width: 950px) {
    .calender .event-triggered span{
        display: none;
    }
}

@media (max-width: 550px) {
    .calender .event-triggered i{
        display: none;
    }
}

.icon_soci {
    color: #673999
}

.icon_soci_disabled {
    color: rgba(203,203,203,.6)!important
}

.calender .status-key{
    max-width: 185px;
    border: 1px solid #dddddd;
    padding: 1rem;
}

.calender .status-key h2 {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.4px;
    color: #bcbcbc;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.calender .status-key .item{
    font-size: 10px;
}

.app-card .flip.disabled .faces .face{
    background: #cccccc;
}
.app-card .flip.disabled .faces .face .description .description-title{
    color: #666666;
}
.app-card .flip.disabled .faces .face .main-icon{
    color: #fff;
}

@media (max-width:1360px) {
    #response-wrapper textarea {
        width: 82%;
        font-size: 14px;
        height: 50px;
        padding: 13px 15px;
    }

    #response-wrapper input[type=submit] {
        width: 18%;
    }

    #response-submit-loader {
        width: 20.5%;
    }
    .calender {
        padding: 0 20px;
    }
}

@media (max-width:570px) {
    #response-wrapper textarea {
        width: 84%;
    }

    #response-wrapper input[type=submit] {
        width: 16%;
    }

    #response-submit-loader {
        width: 18.5%;
    }
    #content-wrapper:has(.calender) {
        padding: 0;
    }
}

@media (max-width:480px) {
    #response-wrapper textarea {
        width: 78%;
    }

    #response-wrapper input[type=submit] {
        width: 22%;
    }

    #response-submit-loader {
        width: 24.5%;
    }
}
