.upgradeModal.ui.tiny.modal {
      width: 539px;
  }
.upgradeModal .content {
    padding: 0 30px 10px !important;
}


p.upgradeModal-text {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: #666666;
  margin-bottom: 14px;
}

p.upgradeModal-text:last-child {
    margin-bottom: 14px;
}

.upgradeModal-imageWrapper {
    display: flex;
    justify-content: center;
}

.upgradeModal-imageWrapper img {
  width: auto;
  max-height: 120px;
  margin-bottom: 6px;
}
