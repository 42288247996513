.upgradeCard {
  cursor: pointer;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  outline: inherit;
  padding: 0;
  border-radius: 16px;
  position: relative;
  box-shadow: 0 3px 3px 0 rgba(26, 26, 26, 0.2), 0 2px 2px 0 rgba(26, 26, 26, 0.12), 0 0 2px 0 rgba(26, 26, 26, 0.14);
  color: #3775f0;
  flex-basis: 80%;
  margin: 0 15px;
  max-width: 1100px;
  transition: box-shadow 0.4s ease;
}

.upgradeCard:disabled {
  cursor: not-allowed;
}

.upgradeCard.disabled {
  cursor: not-allowed;
}

.upgradeCard:active {
  box-shadow: 0 7px 8px 0 rgba(26, 26, 26, 0.26), 0 5px 22px 0 rgba(26, 26, 26, 0.18),
  0 12px 17px 0 rgba(26, 26, 26, 0.2);
}

.upgradeCard:active {
  box-shadow: 0 7px 8px 0 rgba(26, 26, 26, 0.26), 0 5px 22px 0 rgba(26, 26, 26, 0.18), 0 12px 17px 0 rgba(26, 26, 26, 0.2);
}

.upgradeCard:hover {
  box-shadow: 0 7px 8px 0 rgba(26, 26, 26, 0.2),0 5px 22px 0 rgba(26, 26, 26, 0.12),0 12px 17px 0 rgba(26, 26, 26, 0.14);
}

@media (max-width: 1024px) {
  .upgradeCard {
      flex-basis: 100%;
  }
}

@media (max-width: 900px) {
  .upgradeCard br {
      display: none;
  }
}

.upgradeCard-wrapper {
  display: flex;
  justify-content: center;
}

.upgradeCard-description {
  align-items: center;
  background-color: white;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 16px 18px;
}

.upgradeCard-description i {
  font-size: 110px;
  width: auto;
}

.upgradeCard-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.25px;
  margin: 0 0 28px;
  text-align: center;
  text-transform: none;
}

.upgradeCard-subtitle {
  align-items: center;
  color: #444444;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 1.5;
  margin-top: 26px;
  text-align: center;
  text-transform: uppercase;
}

.upgradeCard-callToAction {
  background-color: #3775f0;
  border-radius: 0 0 16px 16px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  margin: 0;
  padding: 20px 32px;
  text-align: center;
}
